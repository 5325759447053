import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Input,
    Select,
    InputGroup,
    InputLeftElement,
    InputRightElement
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { getCountryTelCode } from "./countries";
import { FaPhoneAlt } from "react-icons/fa";

export default function PhoneNumberInput({
     size,
     value,
     country,
     options,
     onChange,
     placeholder,
     lang,
     ...rest
 }) {
    let [number, setNumber] = useState(value || "");
    let [selectedCountry, setSelectedCountry] = useState(country || "");
    let [countryCode, setCountryCode] = useState(
        getCountryTelCode(country) || "egypt"
    );

    useEffect(() => {
        setSelectedCountry(country);
        setCountryCode(getCountryTelCode(country));
    }, [country]);

    const onCountryChange = e => {
        let value = e.target.value;
        let code = getCountryTelCode(value);
        let parsedNumber = new AsYouType().input(`${code}${number}`);
        setCountryCode(code);
        setSelectedCountry(value);
        onChange(parsedNumber);
    };

    const onPhoneNumberChange = e => {
        let value = e.target.value;
        let parsedNumber = new AsYouType().input(`${countryCode}${value}`);

        setNumber(value);
        onChange(parsedNumber);
    };

    return (
        <InputGroup size={size} {...rest}>
            {lang === 'ar' ? 
                    <InputRightElement width="4rem">
                        <Select
                            top="25%"
                            zIndex={1}
                            bottom={0}
                            opacity={0}
                            height="100%"
                            right="-8px"
                            position="absolute"
                            value={selectedCountry}
                            onChange={onCountryChange}
                        >
                            <option value="" />
                            {options.map(option => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </Select>
                        <Flex width="100%" alignItems="right">
                            {selectedCountry ? (
                                <Box width="100%" flex={1}>
                                    <Flag height="5px" style={{borderRadius: "5px", width: "60%", marginRight: "20px"}} code={selectedCountry || ""} />
                                </Box>
                            ) : (
                                <FaPhoneAlt right={"35"} />
                            )}
                        </Flex>
                    </InputRightElement>
                :
                    <InputLeftElement width="4rem">
                        <Select
                            top="25%"
                            left="0"
                            zIndex={1}
                            bottom={0}
                            opacity={0}
                            height="100%"
                            position="absolute"
                            value={selectedCountry}
                            onChange={onCountryChange}
                        >
                            <option value="" />
                            {options.map(option => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </Select>
                        <Flex pl={ selectedCountry ? 2 : 4} width="100%" alignItems="left">
                            {selectedCountry ? (
                                <Box width="100%" flex={1}>
                                    <Flag height="5px" style={{borderRadius: "5px", width: "60%", marginLeft: "-5px"}} code={selectedCountry || ""} />
                                </Box>
                            ) : (
                                <FaPhoneAlt />
                            )}
                        </Flex>
                    </InputLeftElement>
            }
            <Input
                pl="4rem"
                type="tel"
                value={number}
                pattern="[0-9]"
                placeholder={placeholder}
                onChange={onPhoneNumberChange}
            />
        </InputGroup>
    );
}

PhoneNumberInput.defaultProps = {
    options: [],
    size: "md"
};
