import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "#ededed", //for the background color
        color: "black", //for the text color
      },
    },
  },

  components: {
    //set a default color for all buttons
    Button: {
      variants: {
        solid: {
          bg: "blue.500",
          color: "white",
          _hover: {
            bg: "blue.600",
          },
          _active: {
            bg: "blue.700",
          },

          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            _focus: {
              bgColor: "blue.50",
              borderColor: "blue.500",
              boxShadow: "none",
            },
          },
        },
      },
    },
  },

  // colors: {
  //   teal: {
  //     50: "#e6f7f7",
  //     100: "#c3ebea",

  //     200: "#81cdc6",
  //     300: "#4fb9af",
  //     400: "#28a99e",
  //     500: "#05998c", // bg
  //     600: "#048c7f", // hover
  //     700: "#037c6e", // click
  //   },
  // },
});

export default theme;
