import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const userData = localStorage.getItem("userData");
  const accessToken = JSON.parse(userData)?.accessToken;
  return accessToken ? children : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
