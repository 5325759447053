import { React, useState } from "react";
import { AuthWrapper } from "../components/AuthWrapper";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Input,
  Link,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Image
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import http from "../connection/connect";

import PhoneNumberInput from "../components/PhoneNumberComponent/PhoneNumberInput";
import { COUNTRIES } from "../components/PhoneNumberComponent/countries";
import logo from "../assets/animated-right-mark.gif";
import { withTranslation } from 'react-i18next';

const Register = (props) => {
  const { t, i18n } = props;
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const lang = i18n.language;

  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso
  }));

  //form state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //password and confirm password visibility states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //password and confirm password visibility functions
  const handleIconClick = () => setShowPassword(!showPassword);
  const handleConfirmIconClick = () =>
    setShowConfirmPassword(!showConfirmPassword);

  //password and confirmPassword match
  var isMatch = password !== confirmPassword;

  //error state
  const [err, setErr] = useState("");


  //form values
  const formValues = {
    name,
    email,
    username,
    phoneNumber,
    password,
  };

  //form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isMatch) {
      try {
        setLoading(true);
        const response = await http.post("api/auth/register", formValues);

        if (response.data.status === 200) {
          setLoading(false);
          setSuccess(true);
        } else {
          setErr(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          setErr(error.response.data.message);
        } else {
          setErr("Something went wrong. Please try again later.");
        }
      }
    }
  };
  return (
    <>
      {
        success
          ? <Box paddingTop="10%">
              <Center>
                <Box boxSize='sm'>
                  <Image src={logo}/>
                </Box>
              </Center>
              <Center>
                <Box width={'50%'} textAlign={'center'}>
                  {t('registerPage.successRegisterMessage')}
                </Box>
              </Center>
              <Center>
                <Button
                    m={3}
                    onClick={() => {
                      window.location.href = "https://storkplus.com/";
                    }}
                >
                  {t('registerPage.backToHomepage')}
                </Button>
              </Center>
            </Box>
          : <AuthWrapper header={t('registerPage.createNewAccount')}>
            <Divider />
            <form onSubmit={handleSubmit}>
              <FormControl mt={4}>
                <Input
                  placeholder={t('registerPage.name')}
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />

                <Input
                  mt={5}
                  placeholder={t('registerPage.email')}
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />

                <PhoneNumberInput
                    mt={5}
                    country={"EGY"}
                    value={phoneNumber}
                    options={countryOptions}
                    lang={lang}
                    placeholder={t('registerPage.phoneNumber')}
                    onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                />

                <InputGroup mt={5}>
                  <InputLeftAddon children='https://' />
                  <Input
                      placeholder={t('registerPage.username')}
                      type="text"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                  />
                  <InputRightAddon children={process.env.REACT_APP_STORAK_PLUS_URL} />
                </InputGroup>

                <InputGroup mt={5}>
                  <Input
                    placeholder={t('registerPage.password')}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {lang === 'ar' ?
                      <InputLeftElement
                        children={
                          showPassword ? (
                            <ViewOffIcon
                              onClick={() => {
                                handleIconClick();
                              }}
                            />
                          ) : (
                            <ViewIcon
                              onClick={() => {
                                handleIconClick();
                              }}
                            />
                          )
                        }
                      />
                  :
                    <InputRightElement
                      children={
                        showPassword ? (
                          <ViewOffIcon
                            onClick={() => {
                              handleIconClick();
                            }}
                          />
                        ) : (
                          <ViewIcon
                            onClick={() => {
                              handleIconClick();
                            }}
                          />
                        )
                      }
                    />
                  }
                </InputGroup>

                <InputGroup mt={5}>
                  <Input
                    placeholder={t('registerPage.confirmPassword')}
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  {
                    i18n.language === 'ar' ? 
                      <InputLeftElement
                        children={
                          showConfirmPassword ? (
                            <ViewOffIcon
                              onClick={() => {
                                handleConfirmIconClick();
                              }}
                            />
                          ) : (
                            <ViewIcon
                              onClick={() => {
                                handleConfirmIconClick();
                              }}
                            />
                          )
                        }
                      />
                    : 
                      <InputRightElement
                        children={
                          showConfirmPassword ? (
                            <ViewOffIcon
                              onClick={() => {
                                handleConfirmIconClick();
                              }}
                            />
                          ) : (
                            <ViewIcon
                              onClick={() => {
                                handleConfirmIconClick();
                              }}
                            />
                          )
                        }
                    /> 
                  }
                </InputGroup>

                <Box mb={3} color="red.500">
                  {isMatch ? t('registerPage.passwordDoNotMatch') : null}
                </Box>

                <Center>
                  <Button
                    isLoading={isLoading}
                    colorScheme='blue'
                    loadingText={t('registerPage.register')}
                    type="submit"
                    width="100%"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    } }
                  >
                    {t('registerPage.register')}
                  </Button>
                </Center>
                <Center>
                  {typeof err === "string" ? (
                    <Box color="red.500" mt={3}>
                      {err}
                    </Box>
                  ) : (
                    err.length > 0 && (
                      <Box color="red.500" mt={3} textAlign="center">
                        {err.map((error) => {
                          return <p>{error}</p>;
                        })}
                      </Box>
                    )
                  )}
                </Center>
                <Center mt={3}>
                  <Link href="/login" textAlign="center">
                    {t('registerPage.haveAnAccount')}
                  </Link>
                </Center>
              </FormControl>
            </form>
          </AuthWrapper>
      }
    </>
  );
};

export default withTranslation()(Register);
