import React from "react";
import { navigateToUserStore} from "../helpers/global";
import {Loader} from "../components/Loader";

const Home = () => {
  navigateToUserStore('https://storkplus.com')

  return (<>
      <Loader />
  </>);
};

export default Home;
