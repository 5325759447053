import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/main.scss";

import Register from "./pages/register";
import Login from "./pages/login";
import ForgetPassword from "./pages/forget-password";
import ChangePassword from "./pages/change-password";
import Home from "./pages/Home";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Unauthorized from "./pages/unauthorized";
import ConnectToFacebook from "./components/FacebookComponent/ConnectToFacebook";
import CheckValidty from "./PrivateRoute/CheckValid";

import Logout from "./pages/logout";
import { withTranslation } from 'react-i18next';

function App(props) {
  const { i18n  } = props;

  if(i18n.language === 'ar') {
    document.documentElement.lang = "ar";
    document.documentElement.dir = "rtl";
  } else {
    document.documentElement.lang = "en";
    document.documentElement.dir = "ltr";
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/register"
          element={
            <CheckValidty>
              <Register />
            </CheckValidty>
          }
        />
        <Route
          path="/login"
          element={
            <CheckValidty>
              <Login />
            </CheckValidty>
          }
        />
        <Route
            path="/logout"
            element={
                <Logout />
            }
        />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/channels/facebook"
          element={
            <PrivateRoute>
              <ConnectToFacebook />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default withTranslation()(App);
