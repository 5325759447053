import {config} from './config';
import {isEmpty} from 'lodash';

export function refreshPage() {
    window.location.reload(false);
}

export function getUserFullDomain(username = null, path = '/admin') {
    let storakPath = config.storakPlusURL;

    if(username === null) {
        const user = JSON.parse(localStorage.getItem("userData"));
        username = user?.user?.username;
        if(username?.length <= 0) {
            return window.location.href;
        }
    }

    if(isEmpty(path)) {
        path = '/admin';
    }

    storakPath += path;

    return `https://${username}${storakPath}`;
}

export function navigateToUserStore(url = null, path = null) {
    if(url === null) {
        url = getUserFullDomain(null, path);
    }
    return window.location.href = url;
}
