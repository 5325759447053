import React from "react";
import { navigateToUserStore } from "../helpers/global";
import { Loader } from "../components/Loader";


const CheckValidty = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  if(!userData || window.location.pathname.search('register') === 1) {
      return children;
  } else {
      navigateToUserStore();
      return (<>
              <Loader />
          </>
      );
  }
};

export default CheckValidty;
