import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Box,
  Stack,
  Image,
  Heading,
  VStack,
  useToast,
  // SimpleGrid
} from "@chakra-ui/react";

import { FaFacebook } from "react-icons/fa";
import { navigateToUserStore } from "../../helpers/global";
import { ExternalLinkIcon, ArrowBackIcon } from "@chakra-ui/icons";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { config } from "../../helpers/config";
import axios from "axios";

// import FacebookPageCard from "./FacebookPageCard";

const ConnectToFacebook = () => {
  const toast = useToast();
  const isFbConnected = localStorage.getItem("fbAccountConnected");
  const [isLoggedin, setIsLoggedin] = useState(isFbConnected);

  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const onLogout = () => {
    setIsLoggedin(false);
    localStorage.removeItem("fbUser");
    localStorage.removeItem("fbAccountConnected");
  };

  const responseCallback = async (fbUser) => {
    if (fbUser.accessToken) {
      setIsLoading(false);
      setIsLoggedin(true);

      toast({
        title: "Success",
        description: "Facebook account connected successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      localStorage.setItem("fbUser", JSON.stringify(fbUser));
      localStorage.setItem("fbAccountConnected", true);
      const userData = JSON.parse(localStorage.getItem("userData"));

      try {
        await axios.post(
          `${config.storakAuthApiUrl}/api/facebook/${userData.user.id}/fetch-facebook-token`,
          {
            facebookShortLivedToken: fbUser.accessToken,
            facebookUserId: fbUser.userID,
            facebookUserName: fbUser.name,
            facebookProfilePictureUrl: fbUser.picture.data.url,
          },
          {
            headers: { Authorization: "Bearer " + userData.accessToken },
          }
        );

        toast({
          title: "Success",
          description: "Facebook account connected successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      console.log("Not logged in", fbUser);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      py={12}
      minH={"100vh"}
    >
      <VStack
        spacing="4"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxW="md"
        px="4"
        py="3"
        rounded="lg"
      >
        <Box>
          {!error ? (
            localStorage.getItem("fbUser")?.length > 0 ? (
              <Stack
                direction="column"
                spacing="2"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  gap="6"
                  borderRadius="full"
                  boxSize="4rem"
                  src={
                    JSON.parse(localStorage.getItem("fbUser")).picture.data.url
                  }
                  fallbackSrc='https://via.placeholder.com/150'
                />
                <Heading size="md">
                  {JSON.parse(localStorage.getItem("fbUser")).name}
                </Heading>
              </Stack>
            ) : (
              <Heading size="md" color={"red.500"}>
                Not Connected to facebook
              </Heading>
            )
          ) : (
            <Heading size="md" color={"red.500"}>
              Error : {error}
            </Heading>
          )}
        </Box>

        <ButtonGroup gap="4">
          {isLoggedin ? (
            <Button
              onClick={onLogout}
              colorScheme={"red"}
              leftIcon={<ArrowBackIcon />}
            >
              Logout from facebook
            </Button>
          ) : (
            <FacebookLogin
              appId={config.facebookAppId}
              autoLoad={false}
              xfbml={true}
              fields="name,email,picture"
              callback={responseCallback}
              scope="public_profile,pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement,business_management"
              onFailure={(response) => {
                setError(response);
                console.log(response);
              }}
              render={(renderProps) => (
                <Button
                  isLoading={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    setError(null);

                    renderProps.onClick();
                  }}
                  leftIcon={<FaFacebook />}
                  colorScheme={"facebook"}
                >
                  Connect to facebook
                </Button>
              )}
            />
          )}

          <Button
            onClick={() => navigateToUserStore(null, '/admin/bots')}
            leftIcon={<ExternalLinkIcon />}
          >
            Goback to store
          </Button>
        </ButtonGroup>
      </VStack>
    </Box>
  );
};

export default ConnectToFacebook;
