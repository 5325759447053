import { React, useState } from "react";
import { AuthWrapper } from "../components/AuthWrapper";
import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Link,
  Divider,
  FormHelperText, Image,
} from "@chakra-ui/react";

import http from "../connection/connect";
import logo from "../assets/animated-right-mark.gif";

const ForgotPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  //form state
  const [email, setEmail] = useState("");
  const [err, setError] = useState("");

  const formValues = {
    email,
  };

  //form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await http.post("api/auth/forgot-password", formValues);

      if (response.data.status === 200) {
        setLoading(false);
        setSuccess(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <>
      {
        success
            ? <Box paddingTop="10%">
              <Center>
                <Box boxSize='sm'>
                  <Image src={logo}/>
                </Box>
              </Center>
              <Center>
                <Box width={'50%'} textAlign={'center'}>
                  Password successfully changed you Can login now with your new password
                </Box>
              </Center>
              <Center>
                <Button
                    m={3}
                    onClick={() => {
                      window.location.href = "https://storkplus.com/";
                    }}
                >
                  Back to Homepage
                </Button>
              </Center>
            </Box>
            : <AuthWrapper header="Forgot Password">
              <Divider />

              <form onSubmit={handleSubmit}>
                <FormControl pt={4}>
                  <FormHelperText>
                    Forgotten your password? Enter your e-mail address below, and
                    we'll send you an e-mail allowing you to reset it.
                  </FormHelperText>

                  <Input
                    mt={5}
                    placeholder="Email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <Button
                    isLoading={isLoading}
                    colorScheme='blue'
                    loadingText={'Reset my password'}
                    type="submit"
                    width="100%"
                    mt={5}
                  >
                    Reset my password
                  </Button>

                  <Center>
                    {typeof err === "string" ? (
                      <Box color="red.500" mt={3}>
                        {err}
                      </Box>
                    ) : (
                      err.length > 0 && (
                        <Box color="red.500" mt={3} textAlign="center">
                          {err.map((error) => {
                            return <p>{error}</p>;
                          })}
                        </Box>
                      )
                    )}
                  </Center>
                  <Center mt={3}>
                    <Link href="/register" textAlign="center">
                      Don't have an account? Register
                    </Link>
                  </Center>
                  <Center mt={3}>
                    <Link href="/login" textAlign="center">
                      Back to Login?
                    </Link>
                  </Center>
                </FormControl>
              </form>
            </AuthWrapper>
      }
    </>
  );
};

export default ForgotPassword;
